/* eslint-disable react/forbid-prop-types */
import React, { useState } from "react"
import dynamic from "next/dynamic"
import Image from "next/image"
import PropTypes from "prop-types"

import { useGlobalContext } from "../../context/layout-context"

const Title = dynamic(() => import("../title/title"), { ssr: true })
const ButtonPlayVideo = dynamic(() => import("../buttons/button-play-video"), {
  ssr: false,
})
const VideoLoopPlayer = dynamic(() => import("../video/video-loop-player"), {
  ssr: false,
})
const PlayVideo = dynamic(() => import("../buttons/play-video"), { ssr: false })
const VideoBlockPlayer = dynamic(() => import("../video/video-block-player"), {
  ssr: false,
})
const IconClose = dynamic(() => import("../icons/icon-close"), { ssr: false })
const ButtonsGroup = dynamic(() => import("../buttons/buttons-group"), {
  ssr: false,
})
const HeroText = dynamic(() => import("../contentful/hero-text"), { ssr: true })

function HeroInnerContent({
  classHero,
  firstTitleTag,
  firstTitle,
  firstTitleBackground,
  firstTitleColor,
  classFirstTitle,
  firstTitleRotated,
  secondTitleTag,
  secondTitle,
  secondTitleBackground,
  secondTitleColor,
  classSecondTitle,
  secondTitleRotated,
  content,
  desktopImage,
  mobileImage,
  buttons,
  theme,
  overlay,
  backgroundColor,
  alignContent,
  widthCheckbox,
  fixedHeight,
  id,
  source,
  videoLoopId,
  videoLoopFile,
  videoLoopSource,
  promoId,
  title,
  popupPlayer,
  maxWidthClasses,
}) {
  const { globalState } = useGlobalContext()
  const { isMobile, isTablet, isDesktop } = globalState || {}

  const [loopPlaying, setLoopPlaying] = useState(false)
  const [videoPlaying, setVideoPlaying] = useState(false)

  let textAlign
  let buttonsAlign

  switch (alignContent) {
    case "Center":
      textAlign = "text-center"
      buttonsAlign = "justify-center"
      break
    case "Center Mobile":
      textAlign = "text-center lg:text-left"
      buttonsAlign = "justify-center lg:justify-start"
      break
    case "Center Desktop":
      textAlign = "text-left lg:text-center"
      buttonsAlign = "justify-start lg:justify-center"
      break
    case "Left":
      textAlign = "text-left"
      buttonsAlign = "justify-start"
      break
    case "Right":
      textAlign = "text-right lg:text-left"
      buttonsAlign = "justify-start"
      break
    default:
      textAlign = "text-center"
      buttonsAlign = "justify-center"
  }

  return (
    <article
      className={`${classHero} py-20 lg:py-30 relative overflow-hidden z-0 ${
        fixedHeight || "min-h-400px lg:min-h-470px"
      } items-center flex md:items-center`}
      style={{ backgroundColor: `${backgroundColor || "#000000"}` }}
      data-layer-component="hero-inner-content"
    >
      {videoPlaying ? (
        <div className="w-full relative block pb-56pc overflow-hidden">
          <VideoBlockPlayer
            id={id}
            source={source}
            videoPlaying={videoPlaying}
          />
          <button
            type="button"
            onClick={() => setVideoPlaying(false)}
            className="absolute top-4 right-4"
          >
            <IconClose classList="text-white w-8 h-8" />
          </button>
        </div>
      ) : (
        <div className={`container ${maxWidthClasses || "max-w-800px"}`}>
          <div
            className={`flex items-center flex-wrap ${textAlign} lg:flex-row-reverse`}
          >
            <div className="w-full block mb-0">
              {/* Mobile Hero Image */}
              {isMobile && mobileImage && !loopPlaying ? (
                <Image
                  sizes="70vw"
                  src={mobileImage}
                  alt={firstTitle}
                  layout="fill"
                  width={375}
                  height={248}
                  className="object-cover object-center"
                  priority="true"
                  placeholder="blur"
                  blurDataURL="data:image/svg+xml;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNkWL/qPwAEagJa4rsAIAAAAABJRU5ErkJggg=="
                />
              ) : null}

              {isTablet && mobileImage && !loopPlaying ? (
                <Image
                  sizes="70vw"
                  src={mobileImage}
                  alt={firstTitle}
                  layout="fill"
                  width={650}
                  height={340}
                  className="object-cover object-center"
                  priority="true"
                  placeholder="blur"
                  blurDataURL="data:image/svg+xml;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNkWL/qPwAEagJa4rsAIAAAAABJRU5ErkJggg=="
                />
              ) : null}

              {/* Desktop Hero Image */}
              {isDesktop && desktopImage && !loopPlaying ? (
                <Image
                  sizes="80vw"
                  src={desktopImage}
                  alt={firstTitle}
                  layout="fill"
                  className="object-cover object-center"
                  priority="true"
                  placeholder="blur"
                  blurDataURL="data:image/svg+xml;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNkWL/qPwAEagJa4rsAIAAAAABJRU5ErkJggg=="
                />
              ) : null}

              {(videoLoopId && videoLoopSource) ||
              (videoLoopFile && videoLoopSource) ? (
                <VideoLoopPlayer
                  id={videoLoopId || videoLoopFile}
                  source={videoLoopSource}
                  setLoopPlaying={setLoopPlaying}
                />
              ) : null}

              {overlay && (
                <div className="absolute inset-0 bg-gradient-to-b from-black-10 via-black-60 to-black-70" />
              )}
            </div>
            <div className="w-full z-1">
              {firstTitle && (
                <Title
                  tag={firstTitleTag || "h1"}
                  classList={classFirstTitle}
                  content={firstTitle}
                  colorBackgroundTitle={firstTitleBackground}
                  colorTitle={firstTitleColor}
                  rotated={firstTitleRotated}
                  titleAlign={alignContent}
                />
              )}

              {popupPlayer ? (
                <React.StrictMode>
                  {id && source ? (
                    <div className="relative mb-8 flex items-center justify-center">
                      <ButtonPlayVideo
                        id={id}
                        source={source}
                        buttonClassList="w-15 h-15 text-white"
                      />
                    </div>
                  ) : null}
                </React.StrictMode>
              ) : (
                <React.StrictMode>
                  {id && source ? (
                    <div className="relative mb-8 flex items-center justify-center">
                      <PlayVideo
                        id={id}
                        source={source}
                        setVideoPlaying={setVideoPlaying}
                        buttonClassList="w-15 h-15 text-white"
                      />
                    </div>
                  ) : null}
                </React.StrictMode>
              )}

              {secondTitle && (
                <Title
                  tag={secondTitleTag || "h3"}
                  classList={classSecondTitle}
                  content={secondTitle}
                  colorBackgroundTitle={secondTitleBackground}
                  colorTitle={secondTitleColor}
                  rotated={secondTitleRotated}
                  titleAlign={alignContent}
                />
              )}

              {content && (
                <HeroText
                  content={content}
                  theme={theme}
                  widthCheckbox={widthCheckbox}
                />
              )}

              {buttons && (
                <div className="w-full mt-5">
                  <ButtonsGroup
                    buttons={buttons}
                    appearance="ButtonTriangle"
                    color="Yellow"
                    buttonsAlign={buttonsAlign}
                    title={title}
                    promoId={promoId}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </article>
  )
}

HeroInnerContent.propTypes = {
  classHero: PropTypes.string,
  firstTitleTag: PropTypes.string,
  firstTitle: PropTypes.string,
  firstTitleBackground: PropTypes.string,
  firstTitleColor: PropTypes.string,
  classFirstTitle: PropTypes.string,
  firstTitleRotated: PropTypes.bool,
  secondTitleTag: PropTypes.string,
  secondTitle: PropTypes.string,
  secondTitleBackground: PropTypes.string,
  secondTitleColor: PropTypes.string,
  classSecondTitle: PropTypes.string,
  secondTitleRotated: PropTypes.bool,
  content: PropTypes.objectOf(PropTypes.any),
  desktopImage: PropTypes.string,
  mobileImage: PropTypes.string,
}

HeroInnerContent.defaultProps = {
  classHero: "",
  firstTitleTag: "",
  firstTitle: "",
  firstTitleBackground: "",
  firstTitleColor: "",
  classFirstTitle: "",
  firstTitleRotated: false,
  secondTitleTag: "",
  secondTitle: "",
  secondTitleBackground: "",
  secondTitleColor: "",
  classSecondTitle: "",
  secondTitleRotated: false,
  content: {},
  desktopImage: "",
  mobileImage: "",
}

export default HeroInnerContent
